import React from 'react';
import { Bling as GPT } from 'react-gpt';
import HOCMediaQueries from './HOCMediaQueries';

const gptStyle = {
    marginTop: '10px',
    marginBottom: '15px',
    textAlign: 'center',
};

const gptType = {
    intervencao: {
        id: 'div-gpt-ad-1513338245081-6',
        targeting: {
            tipo: 'capa',
            editoria: 'imoveis',
            ambiente: process.env.DFP_ENVIROMENT,
        },
        path: '/54600456/Classi_v_capa_DHTML_940x600',
        mapping: [940, 600],
    },
    intervencao_mobile: {
        id: 'div-gpt-ad-1513338245081-61',
        targeting: {
            tipo: 'capa',
            editoria: 'imoveis',
            ambiente: process.env.DFP_ENVIROMENT,
        },
        path: '/54600456/Classi_v_capa_DHTML_300x250',
        mapping: [300, 250],
    },
    maxiboard: {
        id: 'div-gpt-ad-1513338245081-2',
        targeting: {
            tipo: 'capa',
            editoria: 'imoveis',
            ambiente: process.env.DFP_ENVIROMENT,
        },
        path: '/54600456/Classi_v_capa_970x250',
        mapping: [970, 250],
    },
    small_maxiboard: {
        id: 'div-gpt-ad-1513338245081-1',
        targeting: {
            tipo: 'capa',
            editoria: 'imoveis',
            ambiente: process.env.DFP_ENVIROMENT,
        },
        path: '/54600456/Classi_v_capa_970x150',
        mapping: [970, 150],
    },
    megabox: {
        id: 'div-gpt-ad-1513340433930-3',
        targeting: {
            tipo: 'capa',
            editoria: 'imoveis',
            ambiente: process.env.DFP_ENVIROMENT,
        },
        path: '/54600456/Classi_v_busca_600x300',
        mapping: [600, 300],
    },
    arroba: {
        id: 'div-gpt-ad-1513340433930-4',
        targeting: {
            tipo: 'capa',
            editoria: 'imoveis',
            ambiente: process.env.DFP_ENVIROMENT,
        },
        path: '/54600456/Classi_v_busca_300x250',
        mapping: [300, 250],
    },
    test: {
        id: 'div-gpt-ad-1512045718368-6',
        targeting: { tipo: 'capa', editoria: 'capa' },
        path: '/54600456/Daqui_Capa_4_DSK_300x600',
        mapping: [300, 600],
    },
};

class GPTComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            viewable: false,
        };

        this.isViewable = this.isViewable.bind(this);
    }

    isViewable() {
        this.setState({ viewable: true });
    }

    typeOfGPT(name) {
        const { screenMobile } = this.props;
        let type;

        if (name === 'intervencao') {
            type = screenMobile ? 'intervencao_mobile' : name;
        } else {
            type = screenMobile ? 'arroba' : name;
        }

        return type;
    }

    render() {
        const { gpt, idx } = this.props;
        const { viewable } = this.state;
        let type = this.typeOfGPT(gpt);
        const isOutOfPage = gpt === 'intervencao';

        return (
            <div
                className={
                    gpt === 'intervencao' && viewable
                        ? 'intervencao-holder'
                        : null
                }
            >
                {viewable && (
                    <div style={{ width: gptType[type].mapping[0], marginLeft: 'auto', marginRight: 'auto' }}>
                        <span style={{ fontSize: '12px', position: 'relative', bottom: '-5px' }}>Publicidade</span>
                    </div>
                )}
                <GPT
                    id={`${gptType[type].id}${idx ? '-' + idx : ''}`}
                    style={gptStyle}
                    targeting={gptType[type].targeting}
                    adUnitPath={gptType[type].path}
                    slotSize={
                        gpt !== 'intervencao' ? gptType[type].mapping : null
                    }
                    forceSafeFrame
                    collapseEmptyDiv
                    outOfPage={isOutOfPage}
                    renderWhenViewable={gpt !== 'intervencao'}
                    onImpressionViewable={this.isViewable}
                />
            </div>
        );
    }
}

export default HOCMediaQueries(GPTComponent);
