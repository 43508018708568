import styled from 'styled-components';

export const DetalhesContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, minmax(10%, max-content));
    max-width: 450px;
    justify-content: space-between;
    margin-top: 22px;

    @media (max-width: 768px) {
        margin-top: 10px;
        margin-bottom: 15px;
    }
`;

export const Item = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 768px) {
        padding-right: 0;

        &:last-child {
            margin-right: 0;
        }

        br {
            display: none;
        }
    }
`;

//importar icones no componente que irá usar e passar as imagens como src. <Icon src={svg} />
export const Icon = styled.img`
    width: ${(props) => `${props.tamanhoIcon || 40}px`};
    margin-bottom: 5px;

    @media screen and (max-width: 768px) {
        width: 25px;
        height: 28px;
    }

    @media screen and (max-width: 425px) {
        display: none;
    }
`;

/*
Texto abaixo dos icones. Na const do componente puxar os dados do json.

    const ASDF =({
        titulo,
        descricao,
        areaTotal...
    }) => return ...
*/
export const IconLabel = styled.span`
    font-size: ${(props) =>
        props.fontSize ? `${props.fontSize}px` : `13.5px`};
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;

    @media screen and (max-width: 768px) {
        font-size: 12px;
    }
`;
