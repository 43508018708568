import useApi from './useApi';

const Imoveis = () => {
    const { get, post } = useApi();

    const sendMessage = (formData) =>
        post('/vitrine-imoveis/sendMessage', formData);

    const sendDenuncia = (formData) =>
        post('/vitrine-imoveis/sendDenuncia', formData);

    const toggleFavorito = async (idAnuncio) =>
        get(`/vitrine-imoveis/toggleFavorito`, { idAnuncio });

    const isFavorito = (idAnuncio) =>
        get(`/vitrine-imoveis/favorito/${idAnuncio}`);

    const downloadFolder = (idAnuncio) =>
        get(`/vitrine-imoveis/folder/pdf/${idAnuncio}`, null, {
            responseType: 'blob',
        });

    const previewFolder = (idAnuncio) =>
        get(`/vitrine-imoveis/folder/preview/${idAnuncio}`);

    return {
        sendMessage,
        toggleFavorito,
        isFavorito,
        sendDenuncia,
        downloadFolder,
        previewFolder,
    };
};

export default Imoveis;
