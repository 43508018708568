import styled from 'styled-components';

import { FaHeart, FaRegHeart } from 'react-icons/fa';

export const FavoritoButton = styled.button`
    width: ${(props) => (props.size ? props.size : '13px')};
    height: ${(props) => (props.size ? props.size : '12px')};
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:focus {
        outline: 0;
    }
`;

export const FavoritoButtonActive = styled(FaHeart)`
    color: #e45353;
`;

export const FavoritoButtonInactive = styled(FaRegHeart)`
    color: ${(props) => (props.outlinecolor ? props.outlinecolor : '#ffffff')};
    z-index: 1000;
`;
