import axios from 'axios';

const throwError = (error) => {
    throw error;
};

const UseHttp = (baseUrl, headers = {}) => {
    const http = () => {
        return axios.create({
            baseURL: baseUrl,
            headers,
        });
    };

    const get = (path, params = {}, config = {}) =>
        http()
            .get(path, { params, ...config })
            .then((resp) => resp.data)
            .catch(throwError);

    const post = (path, data, params = {}, config = {}) =>
        http()
            .post(path, data, { params, ...config })
            .then((resp) => resp.data)
            .catch(throwError);

    const put = (path, data, params = {}, config = {}) =>
        http()
            .put(path, data, { params, ...config })
            .then((resp) => resp.data)
            .catch(throwError);

    const del = (path, params = {}, config = {}) =>
        http()
            .delete(path, { params, ...config })
            .then((resp) => resp.data)
            .catch(throwError);

    return {
        http,
        get,
        post,
        put,
        del,
    };
};

export default UseHttp;
