import React, { useEffect, useState } from 'react';
import Image from 'next/image';

const ImageWithFallback = (props) => {
    const { src, alt, fallbackSrc, onHasError, ...rest } = props;
    const [imgSrc, setImgSrc] = useState(src);
    const [imgAlt, setImgAlt] = useState(alt);
    const [isFallback, setIsFallback] = useState(false);

    useEffect(() => {
        setImgSrc(src);
        setImgAlt(alt);
    }, [src, alt]);

    function handleError() {
        setIsFallback(true);
        onHasError();
        setImgAlt(`${alt} não encontrada`);
        setImgSrc(fallbackSrc);
    }

    return (
        <Image
            objectFit={isFallback ? 'cover' : 'fill'}
            {...rest}
            src={imgSrc}
            alt={imgAlt}
            onError={handleError}
        />
    );
};

export default ImageWithFallback;
