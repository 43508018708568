import useHttp from './useHttp';
import { useKeycloak } from '@react-keycloak/nextjs';

const baseUrl = process.env.API_URL;

const UseHttp = () => {
    const { keycloak } = useKeycloak();

    const headers = {
        Authorization: `Bearer ${keycloak.token}`,
    };

    return useHttp(baseUrl, headers);
};

export default UseHttp;
