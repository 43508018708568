export const analyticsEvent = {
    BUSCAR: 'buscar',
    FILTRAR: 'filtrar',
    FAVORITAR: 'favoritar',
    DESFAVORITAR: 'desfavoritar',
    IMPRESSAO_CARD_ANUNCIO: 'impressao_do_card_anuncio',
    EXIBICAO_ANUNCIO: 'exibicao_do_anuncio',
    CADASTRO_NEWSLETTER: 'cadastro_newsletter',
    MENU_TROCA_SECAO: 'menu_troca_secao',
    TROCA_SECAO: 'troca_secao',
    ENTRAR_CONTATO: 'entrar_em_contato',
    ESCREVER_MENSAGEM: 'escrever_mensagem',
    ENVIAR_MENSAGEM: 'enviar_mensagem',
    CLICK_ANUNCIO: 'click_no_anuncio',
    CLICK_TO_CALL: 'click_to_call',
    CLICK_TO_EMAIL: 'click_to_mail',
    CLICK_IMAGEM_ANUNCIO: 'click_imagem_anuncio',
    COMPARTILHAR_FB_TW_EMAIL: 'compartilhar_fb_tw_email',
    REPORTAR_ERRO: 'reportar_erro',
    MAIS_FILTROS: 'mais_filtros',
    VER_TELEFONE: 'ver_telefone',
    CLICK_WHATSAPP: 'click_whatsapp'
}
