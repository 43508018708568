import React, { useState, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/nextjs';
import useImoveis from 'api/imoveis';

import {
    FavoritoButton,
    FavoritoButtonActive,
    FavoritoButtonInactive,
} from 'components/Favoritar/favoritar-styled';
import { sendAnalytics } from 'components/analytics-gjc/analytics-gjc';
import { analyticsEvent } from 'components/analytics-gjc/analytics-gjc-event-enum';

const Favorito = ({
    onChange = null,
    size,
    idAnuncio,
    favorito,
    outlineColor,
}) => {
    const { keycloak } = useKeycloak();
    const { toggleFavorito } = useImoveis();
    const [isActive, setIsActive] = useState(favorito);
    const { isFavorito: IsFavorito } = useImoveis();

    const analyticsJson = { idAnuncio };

    useEffect(() => {
        if (keycloak?.authenticated) {
            IsFavorito(idAnuncio)
                .then((value) => {
                    setIsActive(value);
                })
                .catch((error) => console.err(error));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setIsActive(favorito);
    }, [favorito]);

    const handleToggleFavorito = () => {
        if (keycloak?.authenticated) {
            toggleFavorito(idAnuncio)
                .then((resp) => {
                    if (isActive) {                    
                        sendAnalytics(analyticsEvent.DESFAVORITAR, analyticsJson);
                    } else {
                        sendAnalytics(analyticsEvent.FAVORITAR, analyticsJson);
                    }
                    setIsActive(JSON.parse(resp));
                })
                .finally(() => {
                    onChange && onChange(true);
                });
        } else {
            keycloak.login();
        }
    };

    return (
        <FavoritoButton
            size={size}
            onClick={() => handleToggleFavorito()}
            aria-label={isActive ? 'desfavoritar' : 'favoritar'}
        >
            {isActive ? (
                <FavoritoButtonActive size={size} />
            ) : (
                <FavoritoButtonInactive
                    size={size}
                    outlinecolor={outlineColor}
                />
            )}
        </FavoritoButton>
    );
};

export default Favorito;
