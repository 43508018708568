import React from 'react';

import { AreaSVG, GaragemSVG, QuartosSVG, SuiteSVG } from '../../utils/icons';

import {
    DetalhesContainer,
    Icon,
    IconLabel,
    Item,
} from './detalhes-imovel-styled';

const DetalhesImovel = ({
    areaMax,
    areaMin,
    quartosMax,
    quartosMin,
    suitesMax,
    suitesMin,
    areaTotal,
    qtdeQuartos,
    qtdeSuites,
    qtdMaxVagas,
    qtdeVagas,
    tamanhoIcon,
    fontSize,
    isLancamentos,
}) => {
    return (
        <DetalhesContainer>
            {(!!areaMax || !!areaTotal) && (
                <Item>
                    <Icon
                        src={AreaSVG}
                        tamanhoIcon={tamanhoIcon}
                        alt="Área do imóvel"
                    />
                    <IconLabel fontSize={fontSize}>
                        {isLancamentos ? (
                            <>
                                Área <br />
                                {areaMin} m² - {areaMax} m²
                            </>
                        ) : (
                            <>
                                Área <br />
                                {areaTotal} m²
                            </>
                        )}
                    </IconLabel>
                </Item>
            )}

            {(!!quartosMax || !!qtdeQuartos) && (
                <Item qtdeQuartos={quartosMax}>
                    <Icon
                        src={QuartosSVG}
                        tamanhoIcon={tamanhoIcon}
                        alt="Quantidade de quartos"
                    />
                    <IconLabel fontSize={fontSize}>
                        {isLancamentos ? (
                            <>
                                Quartos <br />
                                {quartosMin} - {quartosMax}
                            </>
                        ) : (
                            <>
                                Quartos <br />
                                {qtdeQuartos}
                            </>
                        )}
                    </IconLabel>
                </Item>
            )}

            {(!!suitesMax || !!qtdeSuites) && (
                <Item qtdeSuites={suitesMax}>
                    <Icon
                        src={SuiteSVG}
                        tamanhoIcon={tamanhoIcon}
                        alt="Quantidade de suítes"
                    />
                    <IconLabel fontSize={fontSize}>
                        {isLancamentos ? (
                            <>
                                Suíte <br />
                                {suitesMin} - {suitesMax}
                            </>
                        ) : (
                            <>
                                Suíte <br />
                                {qtdeSuites}
                            </>
                        )}
                    </IconLabel>
                </Item>
            )}

            {(!!qtdMaxVagas || !!qtdeVagas) && (
                <Item qtdeVagas={qtdeVagas}>
                    <Icon
                        src={GaragemSVG}
                        tamanhoIcon={tamanhoIcon}
                        alt="Quantidade de garagens"
                    />
                    <IconLabel fontSize={fontSize}>
                        {isLancamentos ? (
                            <>
                                Garagem <br />
                                {qtdMaxVagas}
                            </>
                        ) : (
                            <>
                                Garagem <br />
                                {qtdeVagas}
                            </>
                        )}
                    </IconLabel>
                </Item>
            )}
        </DetalhesContainer>
    );
};

export default DetalhesImovel;
